@import '@angular/cdk/overlay-prebuilt.css';
@import './modules/variables';
@import '@angular/material/prebuilt-themes/indigo-pink.css';

.cdk-drag-placeholder {
  visibility: hidden;
}

.visibility-hidden {
  visibility: hidden;
}
.cdk-toaster-pane {
  transition: margin-top 0.1s linear;
}

.z-index-0 { z-index: 0 }
.z-index-1 { z-index: 1; }
.z-index-2 { z-index: 2; }
.z-index-10 { z-index: 10 }
.z-index-100 { z-index: 100 }
.z-index-1000 { z-index: 1000 }

/* Enclosure styling */
.border-bottom-brand { border-bottom: 3px solid $color-brand !important;}
.border-bottom-enclosure { border-bottom: 1px solid #474747 !important; }
.border-top-enclosure { border-top: 1px solid #474747 !important; }
.border-enclosure { border: 1px solid #474747 !important; }
.border-enclosure-light { border: 1px solid $color-list-background !important; }
.border-enclosure-light-dashed { border: 1px dashed $color-list-background !important; }
.border-enclosure-brand { border: 2px solid $color-brand !important; border-bottom: 1px solid $color-list-background !important; }

.border-brand { border: 1px solid $color-brand !important; }
.border-se-white { border: 1px solid $se-white; }
.border-catalogue-tab-bottom { border-bottom: 1px solid $se-super-light-grey; }
.border-catalogue-tab-top { border-top: 1px solid $se-super-light-grey; }
.border-brand-thick { border: 2px solid $color-brand !important; }
.border-width-2 { border-width: 2px !important; }
.border-solid { border-style: solid; }
.border-slot { border: dotted 0.02px #3ECD59; }
.border-slot-comb { border: dotted 0.02px #3ECD59; }

/* text color style */
.text-color-brand { color: $color-brand !important; }
.text-color-grey { color: $se-dark-grey; }
.text-color-light-grey {color: $se-light-grey; }
.text-color-medium-gray {color: $se-medium-grey; }
.text-color-mandatory { color: $color-mandatory; }
.text-hyperlink { color: $color-hyperlink !important; }
.text-color-white { color: #ffffff !important;}
.text-link { color: $se-sky-blue !important;}
.text-color-icon-gray { color: $se-icon-gray !important; }

/* backgrounds */
.bg-brand { background-color: $color-brand !important; }
.bg-brand-bright { background-color: $color-brand-bright !important; }
.white-bg {background: $se-white}
.bg-ultra-light-gray-2 { background: $se-ultra-light-grey-2 }
.bg-ultra-light-gray { background: $se-super-light-grey }
.bg-light-gray { background-color: $se-ultra-light-grey-1; }
.bg-gray { background-color: $se-ultra-light-grey-2 !important; }
.bg-opacity-half { background: rgba(0, 0, 0, 0.5);}
.bg-medium-green { background: $se-medium-green; }
.bg-dark-gray { background: $se-medium-light-grey;}
.bg-widget-selected {background-color: rgba(61, 205, 88);}
.bg-label-unselected {background-color: rgba(237, 237, 237, 0.85);}
.bg-blue-gray { background: $se-medium-blue-grey;}
.bg-light-green { background: $se-sub-header-green;}
.bg-light-blue { background: $se-sky-blue !important;}
.bg-icon-gray { background: $se-icon-gray;}
.bg-table-blue-gray { background: $se-table-blue-gray;}
.bg-red { background: $se-button-red; }
.bg-blue-icon { background: $se-blue-icon; }
.bg-ultra-light-grey { background: $se-ultra-light-gray-4;}
.bg-ultra-light-grey-2 { background: $se-ultra-light-gray-5;}
.bg-disabled { background: $se-disabled !important; }
.bg-inherit { background-color: inherit !important; }
.bg-se-dark-grey { background-color: $se-dark-grey; }
.bg-se-dark-grey-2 { background-color: $se-dark-grey-2; }

.bg-slot-available { background-color: green !important; }
.bg-slot-not-available { background: grey !important; }

/* backgrounds hover */
.bg-gray-hover:hover { background: $se-ultra-light-grey-1 }
.bg-blue-gray-hover:hover { background: $se-blue-gray }
.bg-blue-hover:hover { box-shadow: inset 2px 0 2px 0 $hover-blue; transition: box-shadow 0.2s ease-in-out; }
.bg-light-blue:hover { background: darken($se-sky-blue, 5%) !important;}
.bg-blue-icon:hover { background: darken($se-blue-icon, 5%) !important; }
.bg-ultra-light-gray-hover:hover { background-color: $se-ultra-light-grey-1 !important; }
.bg-icon-gray:hover { background: darken($se-icon-gray, 5%);}
.bg-light-green-hover:hover { background-color: darken($se-sub-header-green, 5%) !important; }
.bg-green-hover:hover { background-color: darken($color-brand, 5%) !important; }

/* backgrounds opacity */
.opacity-8 { opacity: 0.8 !important; }
.opacity-6 { opacity: 0.6 !important; }
.opacity-4 { opacity: 0.4 !important; }
.opacity-3 { opacity: 0.3; }

/*colors */
.color-se-gray { color: $se-gray; }
.color-se-orange { background: $se-orange; }
.color-icon-gray { color: $se-icon-gray;}
.color-black { color: black; }
.color-black:hover { color: black; }
.color-font-gray { color: $se-font-gray;}
.color-icon-red {color: $se-icon-red;}
.color-se-medium-gray { color: $se-medium-grey; }

/* overflows */
.overflow-x-hidden { overflow-x: hidden !important; }
.overflow-hidden-lg  { @media screen and (min-width: $media-screen-m) { overflow: hidden !important; } }
.overflow-y-auto { overflow-y: auto !important; }
.overflow-x-auto { overflow-x: auto !important; }
.overflow-y-auto-md  { @media screen and (max-width: $media-screen-m) { overflow-y: auto !important; } }
.overflow-y-hidden { overflow-y: hidden !important; }
.overflow-unset { overflow: unset !important; }

/* Table td sizes */
$maximumWidth: 250;
@mixin generateWidth {
  @for $i from 1 through $maximumWidth {
    $value: $i;
    .w-#{$i}-px {
      width: #{$i}px;
    }
  }
}

@include generateWidth;

.width-max-content {width: max-content}
.width-inherit {width: inherit !important}
.window-max-width {max-width: $window-max-width}
.project-name-max-width {max-width: $project-name-max-width}
.guest-modal-width {width: $guest-width !important;}

.navigation-height {height: $navigation-height}
.drag-icon-height {height: $drag-icon-height}
.enclosure-list-height {height: $enclosure-list-height}
.button-height { height: $button-height !important;}

/* Positioning */
.top-0 {top: 0};
.top-45 {top: 45px};
.left-0 {left: 0};
.right-0 {right: 0};

/* Behaviour */
.touch-action-none {
  touch-action: none
}

/* Cursor styles */
.cursor-alias {cursor: alias;}
.cursor-all-scroll {cursor: all-scroll;}
.cursor-auto {cursor: auto;}
.cursor-cell {cursor: cell;}
.cursor-context-menu {cursor: context-menu;}
.cursor-col-resize {cursor: col-resize;}
.cursor-copy {cursor: copy;}
.cursor-crosshair {cursor: crosshair;}
.cursor-default {cursor: default !important;}
.cursor-e-resize {cursor: e-resize;}
.cursor-ew-resize {cursor: ew-resize;}
.cursor-grab {cursor: -moz-grab; cursor: -webkit-grab; cursor: grab;}
.cursor-grabbing {cursor: -moz-grabbing; cursor: -webkit-grabbing; cursor: grabbing;}
.cursor-help {cursor: help;}
.cursor-move {cursor: move;}
.cursor-n-resize {cursor: n-resize;}
.cursor-ne-resize {cursor: ne-resize;}
.cursor-nesw-resize {cursor: nesw-resize;}
.cursor-ns-resize {cursor: ns-resize;}
.cursor-nw-resize {cursor: nw-resize;}
.cursor-nwse-resize {cursor: nwse-resize;}
.cursor-no-drop {cursor: no-drop;}
.cursor-none {cursor: none;}
.cursor-not-allowed {cursor: not-allowed !important;}
.cursor-pointer {cursor: pointer;}
.cursor-progress {cursor: progress;}
.cursor-row-resize {cursor: row-resize;}
.cursor-s-resize {cursor: s-resize;}
.cursor-se-resize {cursor: se-resize;}
.cursor-sw-resize {cursor: sw-resize;}
.cursor-text {cursor: text;}
.cursor-w-resize {cursor: w-resize;}
.cursor-wait {cursor: wait;}
.cursor-zoom-in {cursor: zoom-in;}
.cursor-zoom-out {cursor: zoom-out;}

.hidden-sm {@media screen and (max-width: $media-screen-s) {display: none !important;}}
.hidden-xs {@media screen and (max-width: $media-screen-xs) {display: none !important;}}
.hidden-md {@media screen and (max-width: $media-screen-m) {display: none !important;}}
.hidden {display: none !important;}

.font-size-xs {font-size: $font-size-xs !important;}
.font-size-s {font-size: $font-size-s !important;}
.font-size-m {font-size: $font-size-m !important;}
.font-size-l {font-size: $font-size-l !important;}
.font-size-xxl {font-size: $font-size-xxl !important;}
.font-size-xxxxl {font-size: $font-size-xxxxl !important;}
.font-size-8 {font-size: 8px;}
.font-size-10 {font-size: 10px !important;}
.font-size-11 {font-size: 11px;}
.font-size-12 {font-size: 12px;}
.font-size-12-md  { @media screen and (max-width: $media-screen-m) { font-size: 12px; } }
.font-size-13-lg  { @media screen and (min-width: $media-screen-m) { font-size: 13px; } }
.font-size-14 {font-size: 14px;}
.font-size-16 {font-size: 16px;}
.font-size-18 {font-size: 18px;}
.font-size-24 {font-size: 24px;}
.font-size-32 {font-size: 32px;}
.font-size-64 {font-size: 64px;}
.font-size-150 {font-size: 150px;}

.font-weight-600 { font-weight: $font-weight-bold;}
.font-weight-semibold { font-weight: $font-weight-semibold;}
.font-weight-bolder { font-weight: $font-weight-bolder;}
.font-weight-bold { font-weight: $font-weight-bold;}
.font-weight-normal { font-weight: $font-weight-normal;}
.font-weight-900 { font-weight: $font-weight-ultra-bold;}
.font-weight-semilight { font-weight: $font-weight-semilight;}

.text-left { text-align: left !important; }
.text-decoration-none { text-decoration: none; }
.text-decoration-none:hover { text-decoration: none !important; }
.text-decoration-underline { text-decoration: underline; }

.font-shadow-dark { text-shadow: 2px 2px 8px $se-gray; }
.box-shadow { box-shadow: 2px 2px 24px -5px $se-gray; }
.box-shadow-number { box-shadow: 1px 1px 5px $se-gray;}
.flex-wrap-nowrap { flex-wrap: nowrap !important; }
.flex-xs-wrap { @media screen and (max-width: $media-screen-xs) {flex-wrap: wrap !important;} }
.flex-auto { flex: 1 1 auto; box-sizing: border-box;}
.flex-height-30 { flex: 1 1 30px; box-sizing: border-box; max-height: 30px; min-height: 30px; }
.flex-height-35 { flex: 1 1 35px; box-sizing: border-box; max-height: 35px; min-height: 35px; }
.flex-height-60 { flex: 1 1 60px; box-sizing: border-box; max-height: 60px; min-height: 60px; }
.flex-height-65 { flex: 1 1 65px; box-sizing: border-box; max-height: 65px; min-height: 65px; }
.flex-height-80 { flex: 1 1 80px; box-sizing: border-box; max-height: 80px; min-height: 80px; }
.flex-height-full { flex: 1 1 100%; box-sizing: border-box; max-height: 100%; }
.flex-width-80 { flex: 1 1 80px; box-sizing: border-box; max-width: 80px; min-width: 80px; }
.flex-1-1-0 { flex: 1 1 0; box-sizing: border-box;}

.min-width-120 {min-width: 120px; }
.m-m { margin: $m-margin; }
.item-hover:hover { background-color: $se-ultra-light-grey-2; }
.hover-grey-bg:hover { background-color: $se-dark-grey; }
.box-sizing-border { box-sizing: border-box; }
.box-sizing-content { box-sizing: content-box; }
.border-radius-0 { border-radius: 0 !important; }
.border-radius-4 { border-radius: 4px !important; }
.border-radius-8 { border-radius: 8px !important;}
.border-radius-10 { border-radius: 10px !important;}
.border-radius-15 { border-radius: 15px !important;}
.border-radius-30 { border-radius: 30px !important;}
.border-rounded { border-radius: 2rem !important;}
.position-initial { position: initial !important; }
.no-outline { outline: 0 !important; }
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.enable-text-select {
  -webkit-touch-callout: default !important; /* iOS Safari */
  -webkit-user-select: text !important;; /* Safari */
  -moz-user-select: text !important;; /* Old versions of Firefox */
  -ms-user-select: text !important;; /* Internet Explorer/Edge */
  user-select: text !important;; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.enable-all-select {
  -webkit-touch-callout: default !important; /* iOS Safari */
  -webkit-user-select: all !important;; /* Safari */
  -moz-user-select: all !important;; /* Old versions of Firefox */
  -ms-user-select: all !important;; /* Internet Explorer/Edge */
  user-select: all !important;; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.pointer-events-none {
  pointer-events: none !important;
}
.disabled-type {
  opacity: 0.4;
}
.no-border {
  border: 0 !important;
}

/* alerts */
.alert-warning-bg {
  background: #e47f00 !important;
}

.alert-info-bg {
  background: #42b4e6 !important;
}

.alert-success-bg {
  background: #3ecd59 !important;
}

.focused-input { color: #495057; background-color: $se-white; border-color: #80BDFF; outline: 0; box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, .25); border-radius: 3px }
.quantity-icons { font-size: $font-size-l;border: 1px solid $color-list-background;border-radius: 10px; }
.modal-content { border: 0 !important; }
.guest-modal-border { border: 2px solid $se-medium-grey; }
.text-shadow-none { text-shadow: none }
.rotate-90 { transform: rotate(90deg); }
input[type='radio']:focus{ outline: none !important; }
.rounded-border { border-radius: 1rem !important; }
.rounded-border-btn { border-radius: 1.25rem !important; }

.tooltip-override {
  text-align: left !important;
  background-color: $se-medium-light-grey !important;
  color: $se-black !important;
  font-size: 9px !important;
  font-weight: bold !important;
}

.tooltip-arrow-override:before {
  top:50% !important;
  left:0 !important;
  margin-top:-5px !important;
  border-top:5px solid transparent !important;
  border-bottom:5px solid transparent !important;
  border-right:5px solid $se-medium-light-grey !important;
}

.border-bottom-medium-blue-gray {
  border-bottom: 1px solid $se-medium-blue-grey;
}
.resize-non { resize: none !important; }
.button-rounded { border-radius: 1.25rem !important; }
.activeSortHeader {
  color: $color-brand !important;
}

/* bugbattle */
.bb-feedback-dialog-header-logo, .bb-feedback-dialog-infoitem { display: none !important }

/* Green highlight fade in fade out on change */
.value-change-transition {
  -webkit-animation: ACCESSORIES_PRODUCT_ANIMATION 1s; /* Safari 4+ */
  -moz-animation:    ACCESSORIES_PRODUCT_ANIMATION 1s; /* Fx 5+ */
  -o-animation:      ACCESSORIES_PRODUCT_ANIMATION 1s; /* Opera 12+ */
  animation:         ACCESSORIES_PRODUCT_ANIMATION 1s; /* IE 10+, Fx 29+ */
}

@-webkit-keyframes ACCESSORIES_PRODUCT_ANIMATION {
  0%   { background-color: inherit; }
  50%  { background-color: $se-ultra-light-green; }
  100% { background-color: inherit; }
}

@-moz-keyframes ACCESSORIES_PRODUCT_ANIMATION {
  0%   { background-color: inherit; }
  50% { background-color: $se-ultra-light-green; }
  100%   { background-color: inherit; }
}

@-o-keyframes ACCESSORIES_PRODUCT_ANIMATION {
  0%   { background-color: inherit; }
  50%  { background-color: $se-ultra-light-green; }
  100% { background-color: inherit; }
}

@keyframes ACCESSORIES_PRODUCT_ANIMATION {
  0%   { background-color: inherit; }
  50%  { background-color: $se-ultra-light-green; }
  100% { background-color: inherit; }
}

.overlay-comb-bus-bar-sld-focus, .overlay-comb-bus-bar-sld:hover {
  background: linear-gradient(to bottom, rgba(154, 154, 154, 0.5) 3%, rgba(250, 0, 96, 0) 61%);
}

.overlay-device-level-1 {
  background: linear-gradient(to bottom, rgba(177, 0, 67, 0.2) 3%, rgba(250, 0, 96, 0) 61%);
}

.overlay-device-level-1-focus, .overlay-device-level-1:hover {
  background: linear-gradient(to bottom, rgba(177, 0, 67, 0.5) 3%, rgba(250, 0, 96, 0) 61%);
}

.overlay-device-level-2 {
  background: linear-gradient(to bottom, rgba(228, 127, 0, 0.3) 4%, rgba(255, 209, 0, 0) 70%);
}

.overlay-device-level-2-focus, .overlay-device-level-2:hover {
  background: linear-gradient(to bottom, rgba(228, 127, 0, 0.6) 4%, rgba(255, 209, 0, 0) 70%);
}

.overlay-device-level-3 {
  background: linear-gradient(to bottom, rgba(0, 150, 41, 0.3) 8%, rgba(62, 205, 89, 0) 72%);
}

.overlay-device-level-3-focus, .overlay-device-level-3:hover {
  background: linear-gradient(to bottom, rgba(0, 150, 41, 0.6) 8%, rgba(62, 205, 89, 0) 72%);
}

.overlay-device-level-4 {
  background: linear-gradient(to bottom, rgba(0, 134, 208, 0.3) 4%, rgba(66, 180, 230, 0) 69%);
}

.overlay-device-level-4-focus, .overlay-device-level-4:hover {
  background: linear-gradient(to bottom, rgba(0, 134, 208, 0.6) 4%, rgba(66, 180, 230, 0) 69%);
}

.overlay-device-level-5 {
  background: linear-gradient(to bottom, rgba(230, 187, 0, 0.3) 5%, rgba(255, 209, 0, 0) 64%);
}

.overlay-device-level-5-focus, .overlay-device-level-5:hover {
  background: linear-gradient(to bottom, rgba(230, 187, 0, 0.6) 5%, rgba(255, 209, 0, 0) 64%);
}

.label-bg-level-1 {
  background: linear-gradient(48deg, #b10043 -68%, #fa0060 215%);
  opacity: 1;
  color: $se-white;
}

.label-bg-level-2 {
  background: #e47f00;
  opacity: 1;
  color: $se-white;
}

.label-bg-level-3 {
  background: #3ecd59;
  opacity: 1;
  color: $se-white;
}

.label-bg-level-4 {
  background: #42b4e6;
  opacity: 1;
  color: $se-white;
}

.label-bg-level-5 {
  background: #e6bb00;
  opacity: 1;
  color: $se-white;
}

.popover-dispo {
  border-radius: 0 !important;
  border: 0 !important;
  box-shadow: 0 7px 14px 0 $shadow-popover;
}

.icon-flex, .icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-wrapper {
  border-radius: 50%;
  overflow: hidden;
  font-size: 2rem;
  width: 25px;
  height: 25px;
  transform: rotate(90deg);
}

.icon-wrapper img {
  height: 12px;
  width: 12px;
}

.warn-text-length-position {
  bottom: 4px;
  right: 10px;
}

.shepherd-button:focus {
  outline: none !important;
}

.word-break-all {
  word-break: break-all;
}

.projects-window-max-height {
  height: calc(100vh - 15rem);
}

.line-height-1 {
  line-height: 1;
}

.badge-new {
  color: $new-product-border;
  background-color: $new-product-info;
  border: 0.1rem solid;
}

.max-height-transition {
  transition: max-height 0.3s ease-out;
}

.filter-icon-color-brand-on-hover:hover {
  filter: $filter-icon-brand;
}

mat-form-field {
  line-height: 1.5;
}

.mat-form-field-label {
  //color: $color-brand !important;
  color: $se-black !important;
  font-weight: bolder !important;
  //text-transform: uppercase !important;
  font-size: .875rem!important;


  //@extend .text-color-brand, .text-uppercase, .font-weight-bolder;
}

.mat-calendar-header > .mat-calendar-controls > .mat-calendar-period-button > .mat-button-wrapper {
  font-weight: $font-weight-bold;
}

.mat-datepicker-toggle button {
  outline: none;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: $se-light-green !important;
  color: $se-white;
}

.mat-calendar-body-selected {
  background-color: $color-brand !important;
}

.mat-icon-button:hover {
  background-color: $color-brand !important;
  color: $se-white !important;
}

.mat-icon-button:focus {
  outline: none;
}

.mat-button:focus {
  outline: none;
}

.mat-button-disabled:hover {
  background-color: transparent;
}

.mat-datepicker-toggle-active {
  color: $color-brand;
}

.mat-focused .mat-form-field-ripple {
  background-color: $color-brand !important;
}

.mat-calendar-body-cell-content {
  font-weight: $font-weight-bold;
  font-family: Arial,Helvetica,sans-serif;
}

.download-in-progress-image {
  width: 16px; height: 20px
}

// Using custom icon for the checkmark, because the default svg icon is unable to be loaded without using "img-src: data:'"
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after,
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url('./assets/images/checkmark.svg');
}
