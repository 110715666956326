@import "node_modules/bootstrap/scss/bootstrap";
@import "node_modules/@ng-select/ng-select/themes/default.theme.css";
@import './modules/variables';
@import './assets/fonts-icon/css/styles.css';
@import 'node_modules/ngx-ui-switch/ui-switch.component.scss';

.modal-header .close {
  padding: 1rem 0 !important;
  outline: none !important;
}

.dropdown, .dropleft, .dropright, .dropup {
  position: relative;
  width: 100%;
  text-align: right;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

html,
body {
  margin: 0;
  height: 100%;
  font-size: $font-size-s !important;
  font-weight: $font-weight-normal;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

input[type='radio'] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid darkgray;
  border-radius: 80%;
  vertical-align: sub;
}

input[type='radio']:hover {
  box-shadow: 0 0 2px 0px #3DCD58;
  vertical-align: sub;
}

input[type='radio']:before {
  content: '';
  display: block;
  width: 55%;
  height: 55%;
  margin: 23% auto;
  border-radius: 80%;
  vertical-align: sub;
}

input[type='radio']:checked:before {
  background: $se-life-green;
  vertical-align: sub;
}

button, input {
  overflow: visible;
  vertical-align: sub;
  margin-right: 4px !important;
}

.btn-primary {
  color: $se-white;
  background-color: $se-life-green !important;
  border-color: $se-life-green !important;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: $se-white;
  background-color: $se-life-green;
  border-color: $se-life-green;
}

.red {
  color: $se-red;
}

@media (min-width: 481px) and (max-width: 767px) {

  label {
    display: inline-block;
    margin-bottom: .2rem !important;
  }

  .form-group {
    margin-bottom: 0.5rem !important;
  }

  .btn {
    border: 0 !important;
    font-size: $font-size-xs;
    line-height: 1.5;
    border-radius: 0 !important;
    font-weight: $font-weight-semibold;
  }

  .btn-sm {
    padding: .3rem .4rem;
    font-size: $font-size-xs !important;
    line-height: 1.5;
    border: 0;
  }

  .btn-md {
    padding: .35rem .5rem;
    font-size: $font-size-s !important;
    line-height: 1.5;
    border: 0;
  }

  .hidden-sm {
    display: none;
  }

  .visible-sm {
    display: inline;
  }

  .iconSize1 {
    display: none;
  }

  .image-sizing {
    max-width: 84px !important;
    max-height: 100px !important;
  }

  .icon10 {
    font-size: 10px;
    cursor: pointer;
  }

  .icon13 {
    font-size: 12px;
    cursor: pointer;
  }
  .icon14 {
    font-size: 13px;
    margin-right: 6px;
    cursor: pointer;
  }
  .icon16 {
    font-size: 14px;
    vertical-align: middle;
    margin-left: -4px;
    margin-right: 6px;
    cursor: pointer;
  }
  .icon18 {
    font-size: 16px;
    vertical-align: middle;
    margin-left: -4px;
    margin-right: 6px;
    cursor: pointer;
  }
  .icon20 {
    font-size: 18px;
    vertical-align: middle;
    margin-left: -4px;
    margin-right: 6px;
    cursor: pointer;
  }
  .icon24 {
    font-size: 16px !important;
    font-weight: 500;
    line-height: 16px !important;
    cursor: pointer;
  }
  .icon28 {
    font-size: 20px !important;
    font-weight: 500;
    line-height: 20px !important;
    cursor: pointer;
  }

  .icon32 {
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
  }
  .icon36 {
    font-size: 32px !important;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
  }
  .icon46 {
    font-size: 36px !important;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
  }
  .icon60 {
    font-size: 60px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
  }
}

/* Tablets, iPads (portrait and landscape) ---------- */
@media screen and (min-width: 768px) and (max-width: 1024px) {

  .autocomplete-container .suggestions-container ul li a {
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: rgba(0, 0, 0, .87);
    font-size: 12px !important;
    padding: 2px 2px !important;
  }

  label {
    display: inline-block;
    margin-bottom: .2rem !important;
  }
  .form-group {
    margin-bottom: 0.5rem !important;
  }

  .label {
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
  }

  .btn {
    border: 0px !important;
    font-size: $font-size-xs !important;
    line-height: 1.5;
    border-radius: 0rem !important;
    font-weight: $font-weight-semibold;
  }
  // Button
  .btn-sm {
    padding: .3rem .4rem;
    font-size: $font-size-xs !important;
    line-height: 1.5;
    border: 0px;
  }
  .btn-md {
    padding: .35rem .5rem;
    font-size: $font-size-s !important;
    line-height: 1.5;
    border: 0px;
  }
  .btn-lg {
    padding: .35rem 0.5rem;
    font-size: $font-size-s !important;
    line-height: 1.5;
    border: 0;
  }

  .hidden-md {
    display: none;
  }

  .visible-md {
    display: inline;
  }

  .iconSize1 {
    display: none;
  }

  .image-sizing {
    max-width: 84px !important;
    max-height: 84px !important;
  }

  .icon13 {
    font-size: 12px;
    cursor: pointer;
  }
  .icon14 {
    font-size: 13px;
    margin-right: 6px;
    cursor: pointer;
  }
  .icon16 {
    font-size: 14px;
    vertical-align: middle;
    margin-left: -4px;
    margin-right: 6px;
    cursor: pointer;
  }
  .icon18 {
    font-size: 16px;
    vertical-align: middle;
    margin-left: -4px;
    margin-right: 6px;
    cursor: pointer;
  }
  .icon20 {
    font-size: 18px;
    vertical-align: middle;
    margin-left: -4px;
    margin-right: 6px;
    cursor: pointer;
  }
  .icon24 {
    font-size: 18px !important;
    font-weight: 500;
    line-height: 18px !important;
    cursor: pointer;
    width: 16px;
    vertical-align: sub;
  }
  .icon28 {
    font-size: 22px !important;
    font-weight: 500;
    line-height: 22px !important;
    cursor: pointer;
    width: 22px;
    vertical-align: sub;
  }
  .icon32 {
    font-size: 24px;
    vertical-align: middle;
    cursor: pointer;
  }
  .icon36 {
    font-size: 32px !important;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    margin-left: -10px;
  }
  .icon46 {
    font-size: 36px !important;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
  }
  .icon60 {
    font-size: 60px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
  }
}

/* Tablets, iPads (portrait) ---------- */
@media screen and (min-width: 768px) {
  label {
    display: inline-block;
    margin-bottom: .2rem !important;
  }
  .form-group {
    margin-bottom: 0.5rem !important;
  }

  .label {
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
  }

  .btn {
    border: 0px !important;
    font-size: $font-size-s !important;
    line-height: 1.5;
    border-radius: 0rem !important;
    font-weight: $font-weight-semibold;
  }
  // Button
  .btn-sm {
    padding: .3rem .4rem;
    font-size: $font-size-xs !important;
    line-height: 1.5;
    border-radius: 0rem;
    border: 0px;
  }
  .btn-md {
    padding: .35rem .5rem;
    font-size: $font-size-s !important;
    line-height: 1.5;
    border-radius: 0rem;
    border: 0px;
  }
  .btn-lg {
    padding: .4rem 0.6rem;
    font-size: 1rem !important;
    line-height: 1.5;
    border-radius: 0rem;
    border: 0px;
  }

  .iconSize1 {
    display: none;
  }

  .image-sizing {
    max-width: 84px !important;
    max-height: 84px !important;
  }

  .table td, .table th {
    vertical-align: middle !important;
    padding: .15rem !important;
  }
}

/* Tablets, iPads (landscape) ---------- */
@media (min-width: 1025px) and (max-width: 1280px) {
  .autocomplete-container .suggestions-container ul li a {
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: rgba(0, 0, 0, .87);
    font-size: 13px !important;
    padding: 4px 4px !important;
  }

  .icon36 {
    font-size: 32px !important;
    text-align: center;
    line-height: 20px;
    cursor: pointer;
    margin-left: -10px;
  }

  label {
    display: inline-block;
    margin-bottom: .2rem !important;
  }

  .form-group {
    margin-bottom: 0.5rem !important;
  }

  .label {
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
  }

  .btn {
    border: 0 !important;
    font-size: $font-size-s !important;
    line-height: 1.5;
    border-radius: 0 !important;
    font-weight: $font-weight-semibold;
  }

  .btn-sm {
    padding: .3rem .4rem;
    font-size: $font-size-xs !important;
    line-height: 1.5;
    border: 0;
  }

  .btn-md {
    padding: .35rem .5rem;
    font-size: $font-size-s !important;
    line-height: 1.5;
    border: 0;
  }

  .btn-lg {
    padding: .4rem 0.6rem;
    font-size: 1rem !important;
    line-height: 1.5;
    border: 0;
  }

  .image-sizing {
    max-width: 104px !important;
    max-height: 104px !important;
  }

  .table td, .table th {
    vertical-align: middle !important;
    padding: .5rem !important;
  }

  .iconSize {
    font-size: $font-size-xxl;
    vertical-align: sub;
  }
  .iconSize1 {
    padding: 0px 6px 4px 0px;
    vertical-align: sub;
    margin-left: -4px;
    display: inline;
  }
  .iconSize2 {
    padding: 0px 0px 0px 8px;
    vertical-align: sub;
  }

}

/* Desktops and laptops ---------- */
@media (min-width: 1281px) and (max-width: 1366px) {
  .autocomplete-container .suggestions-container ul li a {
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: rgba(0, 0, 0, .87);
    font-size: 13px !important;
    padding: 4px 4px !important;
  }

  .rightDetails {
    overflow-y: auto;
  }

  .configdiv {
    box-shadow: 0px 1px 10px 1px;
    background: $se-white;
    position: absolute !important;
    right: 8px;
    z-index: 9;
  }
  .header {
    text-align: left;
    padding: 10px 15px;
    background: $se-life-green;
    color: $se-white;
    font-weight: $font-weight-bold;
  }
  .configdivcolor {
    text-align: left;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-top: 10px;
    background: #DCDCDC;
  }
  .label {
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
  }

  .mylist-img {
    cursor: pointer;
    width: 60px;
    height: 60px;
    margin: 6px;
  }

  .btn {
    border: 0px !important;
    font-size: $font-size-m;
    line-height: 1.5;
    border-radius: 0rem !important;
    font-weight: $font-weight-semibold;
  }

  .btn-sm {
    padding: .3rem .8125rem;
    font-size: $font-size-xs !important;
    line-height: 1.5;
    border: 0;
  }

  .btn-md {
    padding: .35rem .875rem;
    font-size: $font-size-s !important;
    line-height: 1.5;
    border: 0;
  }

  .image-sizing {
    max-width: 104px !important;
    max-height: 104px !important;
  }

  .table td, .table th {
    vertical-align: middle !important;
    padding: .75rem !important;
  }

  .iconSize {
    font-size: $font-size-xxl;
    vertical-align: sub;
  }
  .iconSize1 {
    padding: 0 6px 4px 0;
    vertical-align: sub;
    margin-left: -4px;
    display: inline;
  }
  .iconSize2 {
    padding: 0 0 0 8px;
    vertical-align: sub;
  }

}

/* Large screens ---------- */
@media screen and (min-width: 1367px) {
  .autocomplete-container .suggestions-container ul li a {
    display: block;
    text-decoration: none;
    cursor: pointer;
    color: rgba(0, 0, 0, .87);
    font-size: 13px !important;
    padding: 4px 4px !important;
  }

  .rightDetails {
    overflow-y: auto;
  }

  .configdiv {
    box-shadow: 0 1px 10px 1px;
    background: $se-white;
    position: absolute !important;
    right: 8px;
    z-index: 9;
  }
  .header {
    text-align: left;
    padding: 10px 15px;
    background: $se-life-green;
    color: $se-white;
    font-weight: $font-weight-bold;
  }
  .configdivcolor {
    text-align: left;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-top: 10px;
    background: #DCDCDC;
  }
  .label {
    font-size: $font-size-xs;
    font-weight: $font-weight-normal;
  }

  .mylist-img {
    cursor: pointer;
    width: 60px;
    height: 60px;
    margin: 6px;
  }

  .btn {
    border: 0 !important;
    font-size: $font-size-m;
    line-height: 1.5;
    border-radius: 0 !important;
    font-weight: $font-weight-semibold;
  }
  .btn {
    font-size: $font-size-s;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0;
  }

  // Button
  .btn-sm {
    padding: .3rem .8125rem;
    font-size: $font-size-xs !important;
    line-height: 1.5;
    border: 0;
  }
  .btn-md {
    padding: .35rem .875rem;
    font-size: $font-size-s !important;
    line-height: 1.5;
    border: 0;
  }
  .btn-lg {
    padding: .4rem 1rem;
    font-size: 1rem !important;
    line-height: 1.5;
    border: 0;
  }

  .image-sizing {
    max-width: 104px !important;
    max-height: 104px !important;
  }

  .iconSize {
    font-size: $font-size-xxl;
    vertical-align: sub;
  }
  .iconSize1 {
    padding: 0 6px 4px 0;
    vertical-align: sub;
    margin-left: -4px;
    display: inline;
  }
  .iconSize2 {
    padding: 0 0 0 8px;
    vertical-align: sub;
  }
}

.search {
  margin-left: 25px;
  position: relative;
  color: #aaa;
  font-size: 16px;
}

.table td, .table th {
  vertical-align: middle !important;
}

.light {
  font-weight: lighter;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul a {
  text-decoration: none;
}

.icon18 {
  font-size: 18px;
  vertical-align: middle;
  margin-left: -4px;
  margin-right: 6px;
  cursor: pointer;
}

.icon20 {
  font-size: 20px;
  vertical-align: middle;
  margin-left: -4px;
  margin-right: 6px;
  cursor: pointer;
}

.icon24 {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
}

.icon28 {
  font-size: 28px;
  font-weight: 500;
  line-height: 28px;
  cursor: pointer;
}

.icon60 {
  font-size: 60px;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
}

.display-flex {
  display: flex;
}

.display-inline-block {
  display: inline-block;
}

.position-absolute {
  position: absolute;
}

.position-relative {
  position: relative;
}

select::-ms-expand {
  display: none;
}

.form-input {
  border-radius: 0;
  height: 45px;
  padding: 10px;
}

.alert {
  padding-left: 0;
}

.btn-primary {
  background: $se-medium-blue !important;
  color: $se-white;
}

.btn-light {
  background: #ededed !important;
}

@media screen and (min-width: 992px) {
  .form-input {
    max-width: 360px;
    width: 360px;
  }
}

.ng-select .ng-select-container {
  min-height: 30px;
  height: auto;
  cursor: pointer !important;
}

.ng-select.ng-select-single .ng-select-container {
  height: auto;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-child {
  padding-left: 10px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  color: $se-true-black;
}

.btn-sm {
  font-weight: 500 !important;
}

.chip {
  display: inline-block;
  padding: 0 7px;
  height: 28px;
  font-size: 9px;
  line-height: 30px;
  border-radius: 15px;
  background-color: $se-medium-light-grey;
  box-shadow: inset 0 0 2px $se-gray;
}

.home-chip {
  @extend .chip;
  width: 28px;
}

.body-bg {
  background: url("/assets/images/body_bg.png") repeat-x;
}

.viewer-editor-icon {
  margin-left: -12px;
  margin-top: 16px;
}

@media screen and (max-width: 575px) {
  .margin-bottom-class {
    margin-bottom: 85px;
  }
}

.notifications-popover {
  overflow: auto;
  max-height: 70%;
  .popover-body {
    padding: 0;
  }
  .popover-header {
    background: white;
    border: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
