@use 'sass:math' as *;

// Medias
$media-screen-xxs: 360px;
$media-screen-xs: 640px;
$media-screen-s: 768px;
$media-screen-m: 1024px;
$media-screen-l: 1280px;
$media-screen-xl: 1440px;
$media-screen-xxl: 1680px;
$media-screen-xxxl: 1920px;

// Borders
$border-light: 1px;

// Images
$image_path: '/assets/images/';

// Fonts
$font-weight-light: 200;
$font-weight-semilight: 300;
$font-weight-normal: 400;
$font-weight-semibold: 500;
$font-weight-bold: 600;
$font-weight-bolder: 700;
$font-weight-ultra-bold: 900;

$font-size-xs: .8125rem; // 13px
$font-size-s: .875rem; // 14px
$font-size-m: 1rem; // 16px
$font-size-l: 1.125rem; // 18px
$font-size-xl: 1.25rem; // 20px
$font-size-xxl: 1.375rem; // 22px
$font-size-xxxl: 1.5rem; // 24px
$font-size-xxxxl: 1.625rem; // 26px

// Colors
$color-brand: #3dcd58;
$color-brand-dark: darken($color-brand, 10%);
$color-brand-darker: darken($color-brand, 20%);
$color-brand-bright: lighten($color-brand, 10%);
$color-brand-brighter: lighten($color-brand, 20%);

$color-secondary: #83D01B;
$color-secondary-dark: darken($color-secondary, 5%);
$color-secondary-darker: darken($color-secondary, 20%);
$color-secondary-bright: lighten($color-secondary, 10%);
$color-secondary-brighter: lighten($color-secondary, 20%);

$color-hyperlink: #007bff;
$color-list-background: #cccccc;

$color-mandatory: #ff3333;

// Animations
$animation-speed-default: .35s;
$animation-speed-slow: .5s;
$animation-speed-fast: .15s;

// Brand Color
$se-dark-green: #007626 !default;
$se-dark-green-subheader: #46962B !default;
$se-sub-header-green: #3ecd59 !default;
$se-logo-green: #009530 !default; //Only for the SE logo color
$se-life-green: #3DCD58 !default; //Primary _ Success
$se-medium-green: #32AD3C !default;
$se-light-green: #70E07C !default;
$se-dark-sky-blue: #0087CD !default;
$se-medium-blue: #219BFD !default;
$se-sky-blue: #42B4E6 !default;
$se-fuchsia-red: #B10043 !default;
$se-red: #DC0A0A !default; //Red Error
$se-honeysuckle-orange: #E47F00 !default;
$se-sunflower-yellow: #FFD100 !default;
$se-sky-blue-10p: #ECF7FC !default;
$se-red-10p: #FCEAE7 !default;
$se-true-black: #000000 !default;
$se-black: #0F0F0F !default;
$se-anthracite-grey: #333333 !default;
$se-ultra-dark-grey: #434343 !default;
$se-super-dark-grey: #505559 !default;
$se-dark-grey: #626469 !default;
$se-dark-grey-2: #7d8085 !default;
$se-transparent-grey: rgba(0, 0, 0, 0.5) !default;
$se-transparent-gray: $se-transparent-grey !default;
$se-medium-grey: #9FA0A4 !default;
$se-light-grey: #CBCBCB !default;
$se-super-light-grey: #E6E6E6 !default;
$se-ultra-light-grey-1: #EDEDED !default;
$se-ultra-light-grey-2: #F7F7F7 !default;
$se-ultra-light-gray-3: #F8F9FA !default;
$se-ultra-light-gray-4: #F4F6F8 !default;
$se-ultra-light-gray-5: #DEE2E6 !default;
$se-white: #FFFFFF !default;
$se-gray: #808080 !default;
$se-blue-gray: #DFE2E7 !default;
$se-medium-light-grey: #D1D5D9 !default;
$se-medium-blue-grey: #EEF1F4 !default;
$se-icon-gray: #888A8C !default;
$se-font-gray: #BABCBF !default;
$se-icon-red: #E35A53 !default;
$se-table-blue-gray: #F4F6F8 !default;
$se-button-red: #FF351F !default;
$se-orange: #FFD100 !default;
$se-ultra-light-green: rgba(112, 224, 124, 0.5);
$se-blue-icon: #0086D0 !default;
$se-disabled: #E9ECEF !default;
$hover-blue: #005be680;
$new-product-info: #E3F4FC;
$new-product-border: #108ED3;

$filter-icon-brand: invert(59%) sepia(94%) saturate(336%) hue-rotate(79deg) brightness(93%) contrast(96%);

// Spacing element
// ==========================================================================
$spacing: 8px !default;
$spacing-small: 4px !default;
$spacing-large: 16px !default;
$spacing-xlarge: 32px !default;

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;
$centered: 1180px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// Typography
// ==========================================================================

$off-black: rgba(0, 0, 0, 0.87) !default;
// Header Styles
$h1-fontsize: 42px !default;
$h2-fontsize: 37px !default;
$h3-fontsize: 30px !default;
$h4-fontsize: 22px !default;
$h5-fontsize: 16px !default;
$h6-fontsize: 14px !default;
$p-fontsize: 16px !default;

$h-margin: 16px !default;
$m-margin: 8px !default;

// Flow Text
// ==========================================================================

$range: $large-screen - $small-screen !default;
$intervals: 20 !default;

$interval-size: div($range, $intervals) !default;

$border-radius: 2px !default;
$border-radius-card: 4px !default;

$line-height-sm: 1.2 !default;

$inner-shadow-top: inset 0 7px 3px -7px rgba(0, 0, 0, 0.5);
$shadow-popover: #00000014;

//== widget
//** widget header border color
$divider-color: $se-super-light-grey;

// Scrollbar
/* width */
$scrollbar-width: 8px; // 6px for width and 8px for bottom
$scrollbar-side-padding: $spacing-small;
$window-max-width: 1920px;
$project-name-max-width: 30ch;

// Header and footer
$footer-height: 35px;
$header-height: 65px;
$footer-z-index: 1000 !important;

// Guest modal
$guest-width: 540px;
$guest-height: 360px;

//navigation and enclosure list height
$navigation-height: 49px;
$drag-icon-height: 14px;
$enclosure-list-height: 500px;

// Button
$button-height: 30px;
